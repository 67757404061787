/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/4/30
 * @description
 *   chat.ts of WeTrade
 */
import { NIM, NIMChatConfig, NIMInitialOptions, NIMMessage } from './chat.api'

let nimInstance: NIM | null = null

export const getNimInstance = async (
  config: NIMChatConfig,
  options?: Partial<NIMInitialOptions>,
) => {
  return new Promise<NIM>((resolve, reject) => {
    if (nimInstance) return resolve(nimInstance)
    const nim: NIM = (window as any).SDK.NIM.getInstance({
      debug: true,   // 是否开启日志，将其打印到console。集成开发阶段建议打开。
      appKey: config.appKey,
      account: config.accId,
      token: config.token,
      db: true, //若不要开启数据库请设置false。SDK默认为true。
      // privateConf: {}, // 私有化部署方案所需的配置
      ...options,
      onconnect () {
        nimInstance = nim
        resolve(nim)
        if (typeof options?.onconnect === 'function') {
          options.onconnect()
        }
      },
      onmsg (msg: NIMMessage) {
        if (typeof options?.onmsgs === 'function') {
          options.onmsgs([msg])
        }
      },
      onofflinemsgs ({ msgs }: { msgs: NIMMessage[] }) {
        if (typeof options?.onmsgs === 'function') {
          options.onmsgs(msgs)
        }
      },
      onroamingmsgs ({ msgs }: { msgs: NIMMessage[] }) {
        if (typeof options?.onmsgs === 'function') {
          options.onmsgs(msgs)
        }
      },
      onerror (error: Error) {
        reject(error)
        if (typeof options?.onerror === 'function') {
          options.onerror()
        }
      },
      ondisconnect () {
        if (typeof options?.ondisconnect === 'function') {
          options.ondisconnect()
        }
      }
    })
  })
}
