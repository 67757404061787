
import { openDialog } from '@/components/popup/popup'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import { isPrime } from '@/state'
import { isDemoAccount } from '@/state/accountType'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PrimeOnly',
  props: {
    text: {
      type: String,
      default: 'membership_22',
    },
    tag: {
      type: [String, Object, Function],
      default: 'div',
    },
    demoFree: Boolean,
  },
  setup (props) {
    return {
      checkPrime (e: MouseEvent) {
        if (props.demoFree && isDemoAccount.value) return

        if (!isPrime()) {
          e.stopPropagation()
          openDialog(LimitDialogTemplate, { i18nPath: props.text })
        }
      },
    }
  },
})
