
import 'video.js/dist/video-js.min.css'
import videojs, { VideoJsPlayer } from 'video.js'
import { defineComponent, nextTick, onBeforeUnmount, shallowRef, watch } from 'vue'
import Pic from '@/components/Pic.vue'
import canAutoplay from 'can-autoplay'

export default defineComponent({
  name: 'TheLiveVideo',
  components: { Pic },
  props: {
    address: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const video = shallowRef()
    const playing = shallowRef(false)
    let player: VideoJsPlayer | null = null

    const play = () => {
      playing.value = true
      nextTick(() => {
        player = videojs(video.value, {
          controls: true,
          autoplay: true,
          fluid: true,
          sources: [{
            // src: 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8',
            // src: 'https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8',
            // src: 'https://pull-rtmp.wetrade.one/wetrade/test.m3u8',
            src: props.address,
            type: 'application/x-mpegURL',
          }],
        }, function () {
          canAutoplay.video().then(resp => {
            if (!resp.result) {
              this.play()
            }
          })
        })
      })
    }

    const stop = () => {
      if (video.value) {
        (video.value as HTMLVideoElement).pause()
      }
    }

    watch(() => props.address, (val) => {
      if (!val) {
        stop()
        playing.value = false
      }
    })

    onBeforeUnmount(() => {
      player?.pause()
      stop()
    })

    return {
      video,
      playing,
      play,
    }
  },
})
