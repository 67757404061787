import { showAlert } from '@/components/popup/popup'
import { readImagesAsDataUrl } from 'essential/file'
import { localGet, localSet } from 'essential/store/localStore'
import { keymap } from '@/config'
import { requestUpdateNickname, updateAvatar, Address } from './setting.api'
import { ref } from 'vue'

// 修改名称
export const updateNickname = (nickName: string) => {
  return requestUpdateNickname({ nickName }).then(() => {
    localSet(keymap.user.nickName, nickName)
  })
}

export const nickName = localGet(keymap.user.nickName)

export type AvatarResp = {
  url: string;
}

export const useUpdateAvatar = (old: string | null) => {
  const avatar = ref(old)

  const update = (e: InputEvent) => {
    const files = (e.currentTarget as HTMLInputElement).files
    if (files) {
      return readImagesAsDataUrl(files).then(([img]) => {
        console.log('jojo', img)
        updateAvatar({
          data: img.data,
          sufix: img.type.split('/')[1],
        })
          .then(resp => {
            avatar.value = (resp as AvatarResp)?.url
            localSet(keymap.user.avatar, avatar.value)
          })
          .catch(() => {
            showAlert('Avatar update failed!')
          })
      })
    }

    return Promise.reject('NO AVATAR SELECTED')
  }

  return [avatar, update]
}

export const getAddressSchema = (data: Address) => {
  return {
    recipientName: {
      pattern: 'noSpecial',
      default: data.recipientName,
    },
    recipientMobile: {
      pattern: /^\d{9,12}$/,
      default: data.recipientMobile,
    },
    recipientCity: {
      pattern: 'noSpecial',
      default: data.recipientCity,
    },
    recipientProvince: {
      pattern: 'noSpecial',
      default: data.recipientProvince,
    },
    recipientZipCode: {
      pattern: 'noSpecial',
      default: data.recipientZipCode,
    },
    recipientAddress: {
      pattern: 'noSpecial',
      default: data.recipientAddress,
    },
  }
}
