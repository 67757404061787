
import { parseToJson } from '@/common/format'
import Pic from '@/components/Pic.vue'
import Time from '@/components/Time.vue'
import { renderEmoji } from '@/modules/community/communities/community'
import { ChatConfig } from '@/modules/membership/member.api'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheChatMessage',
  components: { Time, Pic },
  props: {
    msg: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const custom = parseToJson(props.msg.custom).json ?? {}
    const isSelf = (props.config as ChatConfig).accId === props.msg.from
    return {
      isSelf,
      isMaster: !isSelf && custom.isMaster,
      custom,
      renderEmoji,
    }
  },
})
