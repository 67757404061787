
import Button from '@/components/Button.vue'
import { keymap } from '@/config'
import useRequest from '@/hooks/useRequest'
import { updateNickname } from '@/pages/setting/setting'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { localGet } from 'essential/store/localStore'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheAddChatNickNameDialog',
  components: { Button, DialogTemplate },
  setup (props, ctx) {
    const old = localGet(keymap.user.nickName)
    const name = shallowRef('')
    const [update, progress] = useRequest(updateNickname)

    return {
      old,
      name,
      update () {
        update(name.value).then(() => {
          ctx.emit('close')
          ctx.emit('confirm')
        })
      },
      progress,
    }
  },
})
