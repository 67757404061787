
import { getNimInstance } from 'common/chat/chat'
import {
  NIM,
  NIMChatConfig, NIMCustomMessageObject, NIMImageMessageObject,
  NIMMessage,
  NIMMessageType,
  NIMRoom,
  NIMSendObject, NIMTextMessageObject,
} from 'common/chat/chat.api'
import loadScript from 'essential/dom/loadScript'
import { defineComponent, onBeforeUnmount, shallowReactive, shallowRef, unref, watch } from 'vue'

export default defineComponent({
  name: 'NIMChat',
  props: {
    useRoom: {
      type: Boolean,
      default: true,
    },
    config: {
      type: Object,
      required: true,
    },
    flush: {
      type: Function,
      required: true,
      default: (msgs: NIMMessage[], isNew?: boolean) => undefined,
    },
  },
  emits: ['ready', 'connect', 'disconnect'],
  setup (props, ctx) {
    const config = props.config as NIMChatConfig
    const nim = shallowRef<null | NIM>(null)
    const room = shallowRef<null | NIMRoom>(null)

    onBeforeUnmount(() => {
      room.value?.disconnect()
      // nim.value?.disconnect()
    })

    const connect = () => {
      /**
       * 初始化聊天室
       */
      const connectRoom = (chatroomAddresses: Array<string>) => {
        const chatroom: NIMRoom = (window as any).SDK.Chatroom.getInstance({
          appKey: config.appKey,
          account: config.accId,
          token: config.token,
          chatroomId: config.roomId,
          chatroomAddresses,
          onconnect () {
            // 获取历史消息
            chatroom.getHistoryMsgs({
              msgTypes: ['text'], // 只查询文字消息
              done (error, resp: { msgs: Array<NIMMessage> }) {
                props.flush(resp.msgs)
                // history.value = props.format(resp.msgs)
              },
            })
            room.value = chatroom
            ctx.emit('ready', unref(room))
            // alert('done')
          },
          // 处理新消息
          onmsgs (resp: Array<NIMMessage>) {
            props.flush(resp, true)
            // messages.push(...props.format(resp))
          },
        })
      }

      /**
       * 获取聊天室地址
       */
      const getRoomAddress = (nimInstance: NIM) => {
        nimInstance.getChatroomAddress({
          chatroomId: config.roomId as string,
          done (error, resp) {
            if (!error) {
              connectRoom(resp.address)
            }
          },
        })
      }

      /**
       * nim 初始化
       */
      getNimInstance(config, {
        onmsgs (msgs: NIMMessage[]) {
          if (!props.useRoom) {
            props.flush(msgs)
          }
        },
        onconnect() {
          ctx.emit('connect')
        },
        ondisconnect() {
          ctx.emit('disconnect')
        },
      })
        .then((nimInstance: NIM) => {
          nim.value = nimInstance

          if (!props.useRoom || !config.roomId) {
            return ctx.emit('ready', unref(nim))
          }

          getRoomAddress(nimInstance)
        })
    }

    if (typeof (window as any)?.SDK?.NIM !== 'undefined') {
      connect()
    } else {
      // 加载sdk
      loadScript('/NIM_Web_SDK_v8.3.5.js').then(connect)
    }

    const send = (type: NIMMessageType, body: NIMSendObject) => new Promise((resolve, reject) => {
      const message = {
        ...body,
        done (error: boolean | null, msg: NIMMessage) {
          if (!error) {
            resolve(msg)
            // 自己发送的消息没有新消息提示 手动处理
            props.flush([msg], true)
            // messages.push(msg)
          } else reject(error)
        },
      }

      const target = props.useRoom ? room.value : nim.value

      if (!target) {
        return reject('NO NIM(ROOM) INSTANCE FOUND')
      } else {
        if (type === NIMMessageType.TEXT) {
          target.sendText(message as NIMTextMessageObject)
        } else if (type === NIMMessageType.IMAGE) {
          target.sendFile(message as NIMImageMessageObject)
        } else if (type === NIMMessageType.CUSTOM) {
          target.sendCustomMsg(message as NIMCustomMessageObject)
        } else reject('UNKNOWN NIM MESSAGE TYPE')
      }
    })

    return {
      send,
    }
  },
})
