
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import { showAlert } from '@/components/popup/popup'
import { keymap } from '@/config'
import TheInputTool from '@/modules/community/communities/components/TheInputTool.vue'
import TheChatMessage from '@/modules/membership/components/TheChatMessage.vue'
import { ChatConfig, ChatRole } from '@/modules/membership/member.api'
import PrimeOnly from '@/pages/prime/components/PrimeOnly.vue'
import HoldOn from '@/provider/HoldOn.vue'
import state from '@/state'
import { NIMMessage, NIMMessageType, NIMSendObject } from 'common/chat/chat.api'
import NIMChat from 'common/chat/NIMChat.vue'
import { localGet } from 'essential/store/localStore'
import * as R from 'ramda'
import { computed, defineComponent, nextTick, shallowReactive, shallowRef } from 'vue'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'TheLiveChat',
  components: { PrimeOnly, Icon, Button, TheInputTool, TheChatMessage, NIMChat, HoldOn },
  props: {
    config: Object,
  },
  setup (props) {
    const showInput = shallowRef(false)

    const chat = () => {
      if ((props.config as ChatConfig)?.readonly) {
        showAlert(translate('toast_15', 'Chat is only available during Live hours'))
      } else {
        showInput.value = true
      }
    }

    const listEl = shallowRef<HTMLElement>()

    const scrollToBottom = (behavior: 'auto' | 'smooth') => {
      if (listEl.value) {
        listEl.value.scroll({
          top: listEl.value?.scrollHeight,
          behavior: behavior,
        })
      }
    }

    const inputContent = shallowRef('')
    const sending = shallowRef(false)
    const level = computed(() => state.account.levelModel.level)
    const avatar = localGet(keymap.user.avatar)

    const comment = (send: (type: NIMMessageType, data: NIMSendObject) => Promise<NIMMessage>) => {
      if (sending.value) return
      sending.value = true
      scrollToBottom('auto')
      send(NIMMessageType.TEXT, {
        text: inputContent.value,
        custom: JSON.stringify({
          avatar,
          level: level.value,
          isMaster: (props.config as ChatConfig).roleType === ChatRole.MASTER,
        }),
      })
        .then(() => {
          inputContent.value = ''
          showInput.value = false
        })
        .finally(() => {
          sending.value = false
        })
    }

    const unreadCount = shallowRef(0)

    const markRead = () => {
      unreadCount.value = 0
      scrollToBottom('smooth')
    }

    const history = shallowReactive<Array<NIMMessage>>([])
    const messages = shallowReactive<Array<NIMMessage>>([])

    const flush = (msgs: Array<NIMMessage>) => {
      const history_ = []
      const message_ = []
      const now = Date.now()
      const today = now - (now % (1000 * 60 * 60 * 24))
      for (let i = msgs.length - 1; i >= 0; i--) {
        const msg = msgs[i]
        if (R.isEmpty(msg.text)) continue
        if (msg.time > today) {
          message_.push(msg)
        } else {
          history_.push(msg)
        }
      }
      const lastMsgsCount = history.length + messages.length

      history.push(...history_)
      messages.push(...message_)

      nextTick(() => {
        const el = listEl.value as HTMLElement
        if (el) {
          const notInBottoom = el.scrollHeight - el.scrollTop > 400
          const notInit = lastMsgsCount !== 0
          if (notInBottoom && notInit) {
            unreadCount.value += message_.length
            return
          }
        }
        scrollToBottom(msgs.length < 3 ? 'smooth' : 'auto')
      })
    }

    return {
      history,
      messages,
      listEl,
      inputContent,
      flush,
      chatReady: shallowRef(false),
      showInput,
      chat,
      sending,
      comment,
      unreadCount,
      markRead,
    }
  },
})
