/**
 * @author 阿佑[ayooooo@petalmail.com]
 * @date 2022/4/25 16:43
 * @description
 *   file.ts of WeTrade
 */

type TargetType = 'arrayBuffer' | 'binaryString' | 'dataURL' | 'text'

export type ReadResult = {
  name: string;
  size: number;
  type: string;
  data: string; // @todo 根据TypeType区分
}

const readFilesAs = (type: TargetType, accept = /.*/, encoding?: string) => (files: FileList) => {
  const progress: Promise<ReadResult>[] = []
  const count = files.length

  for (let i = 0; i < count; i++) {
    progress.push(new Promise((resolve, reject) => {
      const file = files[i]

      if (!accept.test(file.type)) {
        reject('INVALID FILE TYPE!')
      }

      const reader = new FileReader()

      reader.onload = (function () {
        return function (e: ProgressEvent<FileReader>) {
          resolve({
            name: file.name,
            size: file.size,
            type: file.type,
            data: e.target?.result as string,
          })
        }
      })()

      reader.onerror = e => reject(e)

      switch (type) {
        case 'arrayBuffer':
          reader.readAsArrayBuffer(file)
          break
        case 'binaryString':
          reader.readAsBinaryString(file)
          break
        case 'dataURL':
          reader.readAsDataURL(file)
          break
        case 'text':
        default:
          reader.readAsText(file, encoding)
      }
    }))
  }

  return Promise.all(progress)
}

export const readImagesAsDataUrl = readFilesAs('dataURL', /image/)
