import requestResult from '@/common/request/request'
import { YesOrNo } from '@/types'
import { NIMChatConfig } from 'common/chat/chat.api'

export type Points = {
  dateTime: number;
  pointSourceName: string;
  pointsValue: number;
}

export const readPoints = requestResult<Array<Points>>('/api/point/list')

export type Gifts = {
  giftId: number;
  giftName: string;
  giftType: number; // 商品类型（1:代金券 2:实物）
  moneys: number;
  poins: number;
  levelRebateFlag: number; // 是否享受折扣（0:不享受 1：享受）
  gift_spec: string;
  giftPic: string;
}

export const readGifts = requestResult<Array<Gifts>>('/api/point/gift/list')

export const exchange = requestResult('/api/point/gift/redemtion')

enum LiveLevel {
  DEFAULT,
  NORMAL,
  META,
}

export type LiveRoom = {
  authorName: string;
  backgroundPicture: string;
  channelId: number;
  channelStatus: YesOrNo;
  chatRoomId: string;
  introduction: string;
  image: string;
  liveLevel: LiveLevel;
  liveTime: string;
  onlineNumber: number;
}

export const readLiveList = requestResult<Array<LiveRoom>>('/api/live/list')

type LiveAddress = {
  hlsDownstreamAddress: string;
  rtmpDownstreamAddress: string;
  isAuth: YesOrNo;
}

export const readLiveAddress = requestResult<LiveAddress>('/api/live/channel/address')

export enum ChatRole {
  USER,
  MASTER
}

export type ChatConfig = NIMChatConfig & {
  name: string;
  roleType: ChatRole;
  userId: number;
  readonly: boolean;
}

export const readChatConfig = requestResult<ChatConfig>('/api/netease/user/get/room/info')

export const readChatState = requestResult<YesOrNo>('/api/netease/user/get/sendMsg/status')
