/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/4/28
 * @description
 *   loadScript.ts of WeTrade
 */
const loadScript = (src: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.onload = resolve
    script.onerror = reject
    script.src = src
    document.body.append(script)
  })
}

export default loadScript
