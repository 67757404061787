/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/4/27
 * @description
 *   live.ts of WeTrade
 */
import * as R from 'ramda'
import { openDialog } from '@/components/popup/popup'
import TheAddChatNickNameDialog from '@/modules/membership/components/TheAddChatNickNameDialog.vue'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import {
  ChatConfig,
  readChatConfig,
  readChatState,
  readLiveAddress,
} from '@/modules/membership/member.api'
import useDelay from 'common/hooks/useDelay'
import { shallowRef } from 'vue'
import { useRouter } from 'vue-router'

const CODES = {
  LEVEL_NOT_MATCH: '900003',
  LACK_OF_NICKNAME: '400002',
}

const useLiveRoom = (liveId: string, chatId: string) => {
  const router = useRouter()
  // 试看两分钟
  const after2Minutes = useDelay(1000 * 60 * 2)

  const liveReady = shallowRef(false)
  const liveAddress = shallowRef('')
  const chatConfig = shallowRef<ChatConfig | null>(null)

  const exit = () => {
    router.back()
  }

  const exitAfter2Minutes = after2Minutes(() => {
    liveAddress.value = ''

    openDialog(LimitDialogTemplate, {
      i18nPath: 'live_19',
      onConfirm: exit,
    })
  })

  const loadChatRoom = (readonly?: boolean) => {
    readChatConfig({
      roomId: chatId,
    })
      .then(resp => {
        if (R.isNil(readonly)) {
          readChatState({
            roomId: chatId,
          }).then(state => {
            chatConfig.value = {
              ...resp,
              readonly: Boolean(!state),
            }
          })
        } else {
          chatConfig.value = {
            ...resp,
            readonly: true,
          }
        }
      })
      .catch(e => {
        if (e.c === CODES.LACK_OF_NICKNAME) {
          openDialog(TheAddChatNickNameDialog, {
            onCancel: exit,
            onConfirm: loadChatRoom,
          })
        }
      })
  }

  readLiveAddress({ channelId: liveId })
    .then(resp => {
      if (resp.hlsDownstreamAddress) {
        liveAddress.value = resp.hlsDownstreamAddress
        if (!resp.isAuth) { // 试看
          openDialog(LimitDialogTemplate, { i18nPath: 'live_17' })
          exitAfter2Minutes()
        }
      }

      // exitAfter2Minutes()

      if (!resp.isAuth) {
        loadChatRoom(true) // 初始化聊天室
      } else {
        loadChatRoom() // 初始化聊天室
      }

      liveReady.value = true
    })
    .catch(e => {
      if (e.c === CODES.LEVEL_NOT_MATCH) {
        openDialog(LimitDialogTemplate, {
          // 根据用户等级选择不同的提示信息
          i18nPath: 'live_32',
          onConfirm: exit,
          onClose (isTrusted: boolean) {
            if (!isTrusted) exit()
          },
        })
      } else {
        exit()
      }
    })

  return {
    liveReady,
    liveAddress,
    chatConfig,
  }
}

export default useLiveRoom
