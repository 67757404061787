import requestResult from '@/common/request/request'

// 修改用户名
export const requestUpdateNickname = requestResult('/api/user/update/name')

export const updateAvatar = requestResult('/api/update/avatar')

export type Address = {
  recipientAddress: string;
  recipientCity: string;
  recipientMobile: string;
  recipientName: string;
  recipientProvince: string;
  recipientZipCode: string;
}

export const readAddress = requestResult<Address>('/api/gift/address')

export const saveAddress = requestResult('/api/gift/edit')
