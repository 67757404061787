
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheLiveNotice',
  components: { Pic, Icon },
  props: {
    content: {
      content: String,
      required: true,
    },
  },
  data () {
    return {
      show: true,
    }
  },
})
